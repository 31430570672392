body {
  header {
    border-top: 5px solid #ed1c24;
    .container {
      width: 970px;
      @media screen and ( max-width: 969px ) {
        width: 770px;
      }
      @media screen and ( max-width: 769px ) {
        width: 100%;
      }
    }
    .branding {
      height: 130px;
      position: relative;
      @media screen and ( max-width: 767px ) {
        height: 200px;
      }
      .logo {
        margin: 0;
      }
      section#block-dkan-sitewide-dkan-sitewide-search-bar {
        position: absolute;
        right: 0;
        top: 47px;
        width: 288px;
        @media screen and ( max-width: 767px ) {
          left: 20px;
          right: auto;
          top: 140px;
        }
        form {
          input.compact-form-input {
            background-color: #f0f0f0;
            border: 1px solid #a6a6a6;
            border-radius: 0;
            font-size: 15px;
            height: 30px;
//            outline: 1px dotted blue;
            padding: 0 0 0 10px;
            width: 260px;
          }
          input[type=submit] {
            background-color: #ed1c24 !important;
            border: none;
            border-radius: 0 !important;
            color: #fff !important;
            font-size: 15px;
            height: 30px;
            padding: 0px;
            right: 0px;
            text-align: center;
            top: 0;
            width: 30px;
          }
        }
      }
    }
    .navigation-wrapper {
      background-color: #f0f0f0 !important;
      box-shadow: 0 2px 3px rgba( 0, 0, 0, .25 );
      font-size: 15px;
      height: 25px;
      @media screen and ( max-width: 767px ) {
        height: auto;
      }
      line-height: 25px;
      position: relative;
      z-index: 2;
      nav.navbar {
        min-height: 0 !important;
        section#block-dkan-sitewide-dkan-sitewide-user-menu {
          display: none;
        }
        ul#main-menu {
          text-align: center;
          width: 100%;
          &:after,
          &:before {
            content: '';
            display: none;
          }
          li {
            float: none !important;
            &:not(:last-child):after {
              @media screen and ( min-width: 768px ) {
                color: #ed1c24;
                content: '\f111';
                font-family: FontAwesome;
                font-size: 6px;
                padding: 0 22px;
                position: relative;
                top: -4px;
              }
              @media screen and ( max-width: 969px ) {
                padding: 0 8px;
              }
            }
            a {
              background-color: #f0f0f0;
              color: #333 !important;
              font-weight: bold;
              padding: 0;
              @media screen and ( max-width: 767px ) {
                padding: 4px;
              }
              text-transform: uppercase;
              &.active,
              &:hover {
                background-color: transparent !important;
                color: #ed1c24 !important;
              };
            }
          }
        }
      }
      #block-dkan-sitewide-dkan-sitewide-user-menu {
        img {
          margin: 0 10px;
        }
        span.links {
          a {
            color: #333 !important;
            font-weight: bold;
            padding: 0 15px;
            text-transform: uppercase;
            &.active,
            &:hover {
              background-color: transparent !important;
              color: #ed1c24 !important;
            };
          }
        }
      }
    }
  }
  h1.page-header {
    background-color: #000;
    color: #fff;
    display: inline-block;
    font-size: 24px;
    font-style: italic;
    font-weight: normal;
    line-height: 32px;
    margin-bottom: 30px;
    padding: 0 12px;
    text-transform: uppercase;
    &:before {
      content: '\f0c9';
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      padding-right: 8px;
    }
  }
  .view-tweets,
  .view-blog-list {
    .view-content {
      .views-row {
        border-bottom: 1px solid #b9b9b9;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        min-height: 80px;
        padding-bottom: 10px;
        .views-field-field-image,
        .views-field-profile-image-url,
        .views-field-picture {
          min-height: 80px;
          float: left;
          width: 80px;
          img {
            border: 4px solid #fff;
            border-radius: 24px;
            height: 48px;
            margin-left: 0;
            padding: 0;
            width: 48px;
          }
        }
        .views-field-created-time,
        .views-field-nothing {
          float: right;
          font-size: 12px;
          font-weight: bold;
        }
        .views-field-body {

        }
        .views-field-text {
          padding-top: 18px;
        }
        .views-field-web-intents {
          margin: 12px 0 0 80px;
          text-align: right;
          span.field-content {
            span {
              a {
                font-size: 12px;
                font-weight: bold;
                margin-left: 8px;
                &:before {
                  font-family: FontAwesome;
                  font-weight: normal;
                  padding-right: 4px;
                };
              }
              &:nth-child(1) a:before { content: '\f112'; }
              &:nth-child(2) a:before { content: '\f079'; }
              &:nth-child(3) a:before { content: '\f005'; }
              &:not(:first-child):before {
                content: '|';
                padding: 0 0 0 4px;
              }
            }
          }
        }
        h1,
        h2 {
          font-size: 14px;
          line-height: 18px;
          margin: 0;
          a {
            color: #000;
          }
        }
        div.submitted {

        }
        div.content {

        }
        ul.links {

        }
      }
    }
  }
  ul.breadcrumb {
    border-bottom: 2px solid #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 0 0 10px 0;
    @media screen and ( max-width: 1199px ) {
      padding-left: 15px;
    }
    & > li + li:before {
      content: '>';
      padding: 0 3px 0 5px;
    }
    margin: 0 auto;
    max-width: 940px;
    li {
      a {
        color: #ed1c24;
        i.icon-large.icon-home {
          &:before {
            content: '\f015';
            font-family: FontAwesome;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
          }
        }
      }
    }
  }
  #main-wrapper {
    margin: 0 auto;
    #main {
      &.container {
        max-width: 970px;
      }
      .region {
        a {
          &.label {
            color: #fff;
          }
          color: #000;

        }
        ul {
          &.links.inline {
            li {
              display: inline-block;
              margin-right: 1px;
              a {
                border: 1px solid #cdcdcd;
                border-radius: 4px;
                color: #005489;
                display: block;
                padding: 2px 8px;
                &:hover {
                  background-color: #002245;
                  border: 1px solid #002245;
                  color: #fff;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      .view-header h2,
      h2.pane-title {
        background-color: #000;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-style: italic;
        font-weight: normal;
        line-height: 26px;
        margin-bottom: 30px;
        padding: 0 12px;
        text-transform: uppercase;
        &:before {
          content: ' ';
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          padding-right: 2px;
        }
      }
      .view-request-data {
        article {
          border-bottom: 1px solid #e2e2e2;
          margin-bottom: 10px;
          padding-bottom: 10px;
          position: relative;
          width: 100%;
          .submitted {
            color: #666;
            font-size: 12px;
            font-weight: bold;
            padding-bottom: 8px;
            a {
              color: #666;
            }
          }
          .views-field-title,
          h2 {
            font-size: 18px;
            font-weight: normal;
            line-height: 18px;
            margin: 0 0 10px 0;
            a {
              color: #000;
            }
          }
          .content {
            font-size: 14px;
            line-height: 24px;
            .data-and-resources {

            }
          }
        }
      }
      ul.pagination.pager {
        .pager-first,
        .pager-last,
        .pager-previous,
        .pager-next {
          a {
            border: 0;
            display: inline-block;
            height: 23px;
            overflow: hidden;
            padding: 0;
            width: 10px;
            &:before {
              background-color: #fff;
              content: url(../img/pagination-prev.png);
              display: inline-block;
              height: 23px;
              width: 10px;
            }
          }
        }
        .pager-previous {
          a {
            margin-right: 16px;
          }
        }
        .pager-next {
          a {
            margin-left: 16px;
            &:before {
              content: url(../img/pagination-next.png);
            }
          }
        }
        .pager-first {
          a {
            margin-right: 8px;
            width: 15px;
            &:before {
              content: url(../img/pagination-first.png);
              width: 15px;
            }
          }
        }
        .pager-last {
          a {
            margin-left: 8px;
            width: 15px;
            &:before {
              content: url(../img/pagination-last.png);
              width: 15px;
            }
          }
        }
        .pager-current,
        .pager-item {
          span,
          a {
            background-color: #c5c5c5;
            border: 2px solid #fff;
            border-radius: 10px;
            color: #fff;
            display: block;
            font-size: 11px;
            font-weight: bold;
            height: 20px;
            line-height: 16px;
            padding: 0;
            text-align: center;
            width: 20px;
          }
        }
        .pager-current {
          span {
            background-color: #ed1c24;
            border-color: #ed1c24;
          }
        }
      }
    }
  }
  &.front,
  &.page-daten-anfragen {
    #main-wrapper {
      #main {
        &.container {
          max-width: none;
        }
      }
    }
  }
  &.front {
    #main-wrapper {
      margin-top: -20px;
      position: relative;
      z-index: 1;
      #main {
        #block-system-main {
          .panel-2col-bricks:after,
          .panel-2col-bricks:before {
            content: '';
            display: none;
          }
          &:after,
          &:before {
            content: '';
            display: none;
          }
        }
        .panel-col-top {
          background-color: #f0f0f0;
          margin: 0;
          padding: 0 0 30px 0;
        }
        .center-wrapper {
          background-color: #f0f0f0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding-bottom: 20px;
          & > div {
            margin: 0;
            @media screen and ( min-width: 940px ) {
              max-width: 460px;
              width: calc( 50% - 20px );
              &:nth-child(1) {
                margin-right: 10px;
              }
              &:nth-child(2) {
                margin-left: 10px;
              }
            }
            @media screen and ( max-width: 939px ) {
              width: calc( 100vw - 20px );
            }
            div.inside {
              margin: 0;
            }
          }
          .pane-blog-list-panel-pane-1 {
            h2.pane-title {
              &:before {
                content: '\f0c9';
              }
            }
          }
          .pane-tweets-panel-pane-1 {
            h2.pane-title {
              &:before {
                content: '\f099';
              }
            }
          }
          .pane-content {

          }
        }
        .pane-block-1 {
          background: url(/sites/all/themes/odk_2016/img/hero.png) no-repeat center center;
          height: 475px;
          position: relative;
          .pane-content {
            p {
              a {
                position: absolute;
                right: calc( ( 100vw - 940px ) / 2 );
                @media screen and ( max-width: 939px ) {
                  right: 10px;
                }
                top: 50px;
              }
            }
          }
        }
        .pane-categories {
          margin: 0 auto;
          max-width: 940px;
          .view-content {
            align-content: flex-start;
            border-left: 1px solid #e2e2e2;
            border-top: 1px solid #e2e2e2;
            display: flex;
            flex-wrap: wrap;
            margin: -30px auto 0;
            position: relative;
            z-index: 1;
            div.grid-2 {
              flex: 1 0 auto;
              width: 129px;
              a {
                background-color: #fff;
                border-bottom: 1px solid #e2e2e2;
                border-right: 1px solid #e2e2e2;
                color: #000;
                display: flex;
                flex-direction: column;
                font-size: 12px;
                height: 156px;
                justify-content: space-between;
                line-height: 15px;
                padding: 16px 10px 0;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                transition: top .2s ease-out, left .2s ease-out, -webkit-transform .2s ease-out;
                &:hover {
                  border: 1px solid #e2e2e2;
                  box-shadow: 3px 3px 5px rgba( 0, 0, 0, .25 );
                  transform: scale(1.1);
                  z-index: 10;
                }
                i.icon-large {
                  color: #306a9a;
                  display: block;
                  font-family: FontAwesome;
                  font-size: 32px;
                  height: 52px;
                  line-height: 52px;
                  &.icon-cat-42:after { content: '\f0c0'; }
                  &.icon-cat-43:after { content: '\f040'; }
                  &.icon-cat-44:after { content: '\f0ac'; }
                  &.icon-cat-45:after { content: '\f02d'; }
                  &.icon-cat-46:after { content: '\f0fa'; }
                  &.icon-cat-47:after { content: '\f015'; }
                  &.icon-cat-48:after { content: '\f06e'; }
                  &.icon-cat-49:after { content: '\f153'; }
                  &.icon-cat-50:after { content: '\f046'; }
                  &.icon-cat-51:after { content: '\f004'; }
                  &.icon-cat-52:after { content: '\f0d1'; }
                  &.icon-cat-53:after { content: '\f18c'; }
                  &.icon-cat-54:after { content: '\f080'; }
                  &.icon-cat-55:after { content: '\f1c0'; }
                  &.icon-cat-118:after { content: '\f29a'; }
                }
                p.data-name {
                  display: block;
                  min-height: 50px;
                  margin: 10px 0 0 0;
                }
                p.data-count {
                  font-weight: bold;
                  margin: 0 0 10px 0;
                  span {
                    color: #333;
                  }
                }
              }
              &:nth-child(2n) {
                a {
                  background-color: #f7f7f7;
                }
              }
            }
          }
        }
        .pane-block-2 {
          background: url(/sites/all/themes/odk_2016/img/api-bg.png) no-repeat center center;
          height: 155px;
          .pane-content {
            color: #fff;
            font-size: 18px;
            line-height: 20px;
            margin: 0 auto;
            max-width: 740px;
            padding: 1px 0;
            text-align: center;
            a {
              border: 1px solid #fff;
              color: #fff;
              display: block;
              font-size: 28px;
              font-style: italic;
              line-height: 45px;
              max-width: 500px;
              margin: 30px auto 10px;
              text-transform: uppercase;
              &:before {
                content: '\f05a';
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                padding-right: 8px;
              }
              &:hover {
                text-decoration: none;
              };
              strong {
                font-weight: bold;
              }
            }
          }
        }

        .pane-dkan-datasets-panel-pane-2 {
          margin: 0 auto;
          @media screen and ( max-width: 939px ) {
            margin: 0 10px;
          }
          max-width: 940px;
          h2.pane-title {
            &:before {
              content: '\f1c0';
            }
          }
          .views-field-nid {
            span.field-content {
              & > span {
                font-size: 14px;
                font-weight: bold;
              }
              div.item-list {
                display: inline-block;
                ul.resource-list {
                  display: inline-block;
                  margin-left: 4px;
                  position: relative;
                  top: -1px;
                  li {
                    display: inline-block;
                    margin: 0;
                    a {
                      color: #fff;
                      margin: 0;
                      padding: 0 4px 1px;
                      text-transform: uppercase;
                    }
                    &:not(:last-child):after {
                      content: '/';
                      padding: 0 0 0 4px;
                      position: relative;
                      top: 2px;
                    }
                  }
                }
              }
            }
          }
          .view-dkan-datasets {
            .view-header {
              display: none;
            }
            .view-content {
              .views-view-grid {
                background-color: #fff;
                margin: 0 auto;
                @media screen and ( min-width: 940px ) {
                  max-width: 100%;
                }
                position: relative;
                @media screen and ( max-width: 939px ) {
                  width: calc( 100vw - 20px ) !important;
                }
                .slick-track {
                  ul.row {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    width: 940px !important;
                    @media screen and ( max-width: 939px ) {
                      width: calc( 100vw - 20px ) !important;
                    }
                    &:before,
                    &:after {
                      content: '';
                      display: none;
                    }
                    li.col {
                      border-bottom: 1px solid #b9b9b9;
                      margin: 0 0 10px 0;
                      height: 130px;
                      padding: 0 0 10px 0;
                      width: calc( 50% - 10px );
                      @media screen and ( max-width: 767px ) {
                        width: calc( 100vw - 20px );
                      }
                      .inside {
                        position: relative;
                        width: 100%;
                        .views-field-title,
                        h2 {
                          font-size: 18px;
                          font-weight: normal;
                          line-height: 18px;
                          margin: 0 0 10px 0;
                          a {
                            color: #000;
                          }
                        }
                        .content {
                          font-size: 14px;
                          line-height: 24px;
                          .data-and-resources {

                          }
                        }
                      }
                      ul.links {
                        display: none;
                      }
                    }
                  }
                }
                .slick-prev,
                .slick-next {
                  background: transparent;
                  border: none;
                  color: transparent;
                  cursor: pointer;
                  display: block;
                  @media screen and ( max-width: 939px ) {
                    display: none !important;
                  }
                  font-size: 0;
                  height: 57px;
                  line-height: 0;
                  margin-top: -29px;
                  outline: none;
                  padding: 0;
                  position: absolute;
                  top: 45%;
                  width: 24px;
                  &:before {
                    color: #ed1c24;
                    content: '\f053';
                    content: url(../img/slick-prev.png);
                    font-family: FontAwesome;
                    font-size: 20px;
                    line-height: 1;
                  }
                }
                .slick-prev {
                  left: -50px;
                }
                .slick-next {
                  right: -50px;
                }
                .slick-next:before {
                  content: '\f054';
                  content: url(../img/slick-next.png);
                }
                ul.slick-dots {
                  margin: -5px 0 35px 0;
                  padding: 0;
                  text-align: center;
                  li {
                    display: inline-block;
                    button {
                      background: transparent;
                      border: 0;
                      color: transparent;
                      cursor: pointer;
                      display: block;
                      font-size: 0;
                      height: 20px;
                      line-height: 0;
                      outline: none;
                      padding: 5px;
                      position: relative;
                      width: 20px;
                      &:before {
                        content: '\f111';
                        color: #c5c5c5;
                        font-family: FontAwesome;
                        font-size: 9px;
                        height: 20px;
                        left: 0;
                        line-height: 20px;
                        position: absolute;
                        text-align: center;
                        transition: color .1s ease-in-out, font-size .1s ease-in-out;
                        top: 0;
                        vertical-align: middle;
                        width: 20px;
                      };
                    }
                    &.slick-active {
                      button {
                        &:before {
                          color: #ed1c24;
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.page-tweets-raw {
    h1.page-header {
      &:before {
        content: '\f099';
      }
    }
  }
  &.page-daten-anfragen {
    #main-wrapper {
      & > .container {
        background-color: #f0f0f0;
        width: 100%;
        width: 100vw;
        .region-content {
          margin: 0;
          .panel-col-top {
            padding: 20px 0;
            .inside {
              margin: 0 auto;
              max-width: 938px;
              @media screen and ( max-width: 979px ) {
                width: calc( 100vw - 40px );
              }
              .panel-pane.pane-1 {
                background: url(../img/questionmark.png) no-repeat 0 0;
                padding-left: 126px;
                h1 {
                  font-size: 28px;
                  font-weight: normal;
                }
              }
              #data-request-inline-form {
                background-color: #fff;
                border: 1px solid #fdfdfd;
                &.closed {
                  #form-toggle-bar {
                    background-image: none;
                    height: 27px;
                    #form-toggle {
                      background-position: right -27px;
                    }
                  }
                  .pane-content {
                    display: none;
                  }
                }
                #form-toggle-bar {
                  background: url(../img/transition-line.png) repeat-x 0 bottom;
                  height: 32px;
                  position: relative;
                  width: 100%;
                  #form-toggle {
                    background: url(../img/form-toggle.png) no-repeat right 0;
                    cursor: pointer;
//                    float: right;
                    height: 27px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 768px;
                    @media screen and ( max-width: 939px ) {
                      width: calc( 100vw - 180px );
                    }
                  }
                }
                h2.pane-title {
                  margin-top: 0;
                }
                .pane-content {
                  padding: 20px;
                  form {
                    label {
                      display: inline-block;
                      font-size: 14px;
                      font-weight: normal;
                    }
                    input[type=text],
                    textarea {
                      border-radius: 0;
                      background-color: #f0f0f0;
                    }
                    .field-type-text,
                    .field-type-email {
                      display: inline-block;
                      position: relative;
                      width: calc( 50% - 10px);
                      @media screen and ( max-width: 979px ) {
                        display: block;
                        width: 100%;
                      }
                      label {
                        width: 140px;
                      }
                      input {
                        display: inline-block;
                        width: 290px;
                        @media screen and ( max-width: 379px ) {
                          width: 100%;
                        }
                      }
                      span.help-block {
                        display: block;
                        height: 14px;
                        overflow: hidden;
                        position: absolute;
                        right: 298px;
                        top: 6px;
                        width: 14px;
                        z-index: 1;
                        @media screen and ( max-width: 979px ) {
                          left: 120px;
                          right: auto;
                        }
                        &:before {
                          background-color: #ed1c24;
                          border-radius: 7px;
                          color: #fff;
                          content: '?';
                          display: block;
                          font-size: 11px;
                          font-weight: bold;
                          height: 14px;
                          line-height: 14px;
                          text-align: center;
                          text-indent: 0;
                          width: 14px;
                          z-index: 2;
                        }
                        &:hover {
                          background-color: #fff;
                          border: 1px solid #ed1c24;
                          border-radius: 4px;
                          color: #333;
                          font-size: 12px;
                          height: auto;
                          line-height: 15px;
                          padding: 5px;
                          top: -6px;
                          width: auto;
                          z-index: 3;
                          &:before {
                            display: none;
                          }
                        }
                      }
                    }
                    #edit-field-request-data-email,
                    #edit-field-request-data-owner {
                      margin-left: 20px;
                      @media screen and ( max-width: 979px ) {
                        margin-left: 0;
                      }
                    }
                  }
                }
                fieldset.filter-wrapper {
                  border: 0;
                  margin: 0;
                  padding: 0;
                  .fieldset-wrapper {
                    padding: 4px 0;
                    .form-wrapper {
                      padding: 0;
                      p {
                        font-size: 14px;
                        margin-bottom: 0;
                        a {
                          color: #ed1c24 !important;
                        }
                        &:after {
                          color: #ed1c24;
                        }
                      }
                    }
                  }
                }
                #edit-field-request-data-public {
                  label {
                    padding-left: 0;
                    input[type=checkbox] {
                      background: transparent;
                      cursor: pointer;
                      height: 20px;
                      margin: 0 12px 0 0;
                      padding: 0;
                      position: relative;
                      visibility: hidden;
                      width: 40px;
                      z-index: 1;
                      &:not(:checked) ~ span.ckbx {
                        background-position: 0 -20px;
                      }
                    }
                    span.ckbx {
                      background: #fff url(../img/checkbox.png) no-repeat 0 0;
                      cursor: pointer;
                      content: ' ';
                      display: inline-block;
                      height: 20px;
                      left: 0;
                      pointer-events: none;
                      position: absolute;
                      visibility: visible;
                      width: 40px;
                      z-index: 0;
                    }
                  }
                }
                .form-actions {
                  text-align: right;
                  input[type=submit] {
                    border-color: #ed1c24;
                    border-radius: 0;
                    background-color: #ed1c24;
                    margin: 0;
                    padding: 1px 20px;
                  }
                }
              }
            }
          }
          .panel-col-middle {
            background-color: #fff;
            & > .inside {
              margin: 0 auto;
              max-width: 940px;
              padding: 20px 0;
              position: relative;
              @media screen and ( max-width: 979px ) {
                width: calc( 100vw - 40px );
              }
              @media screen and ( max-width: 767px ) {
                padding-top: 0px;
              }
              .pane-views-exp-request-data-panel-pane-1 {

                @media screen and ( max-width: 767px ) {

                }
                .views-exposed-widget {
                  padding: 0;
                  &.views-widget-sort-by {
                    border-radius: 0;
                    position: relative;
                    label {
                      background-color: #f0f0f0;
                      border: 1px solid #a6a6a6;
                      display: inline-block;
                      font-size: 14px;
                      font-weight: normal;
                      height: 30px;
                      line-height: 30px;
                      padding: 0 8px;
                      position: absolute;
                      top: 0;
                      width: 282px;
                      z-index: 0;
                      &:after {
                        background: #ed1c24 url(../img/form-toggle.png) no-repeat right -26px;
                        border: 1px solid #ed1c24;
                        content: ' ';
                        display: block;
                        height: 30px;
                        position: absolute;
                        right: -1px;
                        width: 30px;
                        top: -1px;
                        z-index: 1;
                      }
                    }
                    select#sort {
                      opacity: 0;
                      position: relative;
                      z-index: 1;
                    }
                  }
                  &.views-submit-button {
                    display: none;
                  }
                }
              }
              .view-header {
                h2 {
                  display: inline-block;
                  @media screen and ( max-width: 767px ) {
                    margin-bottom: 80px !important;
                  }
                  &:before {
                    content: '\f1c0' !important;
                    margin-right: 4px;
                  }
                }
              }
              .view-content {
                .views-row {
                  position: relative;
                  .status-info {
                    .status-label {
                      color: #000;
                      font-size: 14px;
                      font-weight: bold;
                      padding: 0;
                    }
                    .status-icon {
                      border-radius: 2px;
                      color: #fff;
                      display: inline-block;
                      margin-bottom: 8px;
                      padding: 0 8px;
                      &:before {
                        content: '';
                        font-family: FontAwesome;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 24px;
                      }
                      &.question_mark:before {
                        content: '\f128  |';
                      }
                      &.ready:before {
                        content: '\f00c  |';
                      }
                      &.not_posible:before {
                        content: '\f05e  |';
                      }
                      &.requested:before {
                        content: '\f002  |';
                      }
                      &.poll:before {
                        content: '\f085  |';
                      }
                      &.partially_provided:before {
                        content: '\f074  |';
                      }
                      .status-message {
                        font-size: 11px;
                        font-weight: bold;
                        line-height: 11px;
                        position: relative;
                        top: -2px;
                      }
                    }
                  }
                  .comment-counts {
                    color: #ed1c24;
                    font-size: 12px;
                    font-weight: bold;
                    position: absolute;
                    right: 0;
                    top: 0;
                  }
                  .node-readmore {
                    bottom: 12px;
                    position: absolute;
                    right: 0;
                  }
                }
              }
            }
            ul.links {
              text-align: right;
            }
          }
        }
      }
    }
  }
  &.page-blog,
  &.page-node,
  &.node-type-dataset,
  &.page-search,
  &.page-dataset {
    #main-wrapper {
      #main.container {
        #block-views-categories-block-1 {
          display: none;
        }
        .radix-layouts-sidebar {
          .panel-panel-inner {
            h2.pane-title {
              &:after {
                content: ''!important;
              }
              cursor: pointer;
              font-style: normal;
              margin-bottom: 0;
              padding: 0 8px;
              text-transform: none;
              width: 100%;
              &.closed  {
                background-position: right -44px;
                & + .pane-content {
                  display: none;
                }
              }
            }
          }
        }
        .fivestar-widget-static,
        .fivestar-widget {
          .empty,
          .total-votes,
          .average-rating {
//                        display: none;
            font-size: 11px;
            line-height: 15px;
          }
          /*
          .star,
          .star a,
          .star span.on {
            background-image: url(../img/star.png) !important;
          }
          */
        }
        .view-dkan-datasets {
          .view-content {
            .views-row {
              border-bottom: 1px solid #eee;
              margin-bottom: 0px;
              padding-bottom: 20px;
              position: relative;
              p {
                font-size: 14px;
                line-height: 24px;
              }
              article {
                h2 {
                  padding-bottom: 12px;
                }
                .content {
                  position: relative;
                }
                .field-name-field-rating {
                  position: absolute;
                  right: 0;
                  top: 0;
                  .field-label {
                    display: none;
                  }
                }
              }
              .dataset-info {
                font-size: 11px;
                line-height: 15px;
                text-align: right;
                top: 30px;
                .status-label {
                  display: none;
                }
                .status-icon {
                  display: inline-block;
                  margin-bottom: 8px;
                  .status-message {
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 11px;
                    position: relative;
                    top: -2px;
                  }
                }
              }
              .node-readmore {
                display: none;
              }
              .comment-counts {
                bottom: 12px;
                color: #ed1c24;
                font-size: 12px;
                font-weight: bold;
                text-align: right;
              }
              .count-resource {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  &.node-type-dataset {
    #main-wrapper {
      #main.container {
        article {
          .content {
            position: relative;
            h2 {
              padding-bottom: 12px;
            }
            .content {
              position: relative;
            }
            .field-name-body {
             padding-top: 48px;
            }
            .field-name-field-rating {
              position: absolute;
              right: 0;
              .field-label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.page-blog {

    .item-list span {
      color: #909090;
      font-size: 90%;
    }

    .view-blog-list {
      .view-content {
        .views-row {
          .views-field-field-image,
          .views-field-profile-image-url,
          .views-field-picture {
            img {
            }
          }
          .views-field-created-time,
          .views-field-nothing {
            float: none;
          }
        }
      }
    }
  }
  &.page-anwendungen {
    .field-content.portfolio_desc {
      margin: 25px 0;
    }
  }
  footer#footer {
    background-color: #ed1c24;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    .container {
      background: url(../img/kommunikationsmuster.png) no-repeat right 64px;
      height: 140px;
      position: relative;
      width: 940px;
      @media screen and ( max-width: 959px ) {
        margin: 0 20px;
        width: calc( 100vw - 40px );
      }
      @media screen and ( max-width: 769px ) {
        margin: 0 10px;
        width: calc( 100vw - 20px );
      }
      .pull-right {
        left: 0;
        position: absolute;
        top: 24px;
        width: 100%;
        ul.menu.nav {
          li {
            display: inline-block;
            @media screen and ( max-width: 399px ) {
              display: block;
              max-width: 240px;
            }
            a {
              color: #fff;
              font-size: 12px;
              font-weight: bold;
              padding: 0 12px 0 0;
              &:hover {
                background-color: transparent;
                text-decoration: underline;
              }
            }
          }
        }
        ul.footer-icons {
          position: absolute;
          right: 88px;
          top: 0px;
          @media screen and ( max-width: 639px ) {
            right: 66px;
          }
          @media screen and ( max-width: 499px ) {
            right: 4px;
          }
          li {
            display: inline-block;
            margin-left: 20px;
            @media screen and ( max-width: 639px ) {
              margin-left: 10px;
              img {
                height: 26px;
                width: 26px;
              }
            }
          }
        }
        #block-dkan-sitewide-dkan-sitewide-user-menu--2 {
          display: none;
        }
      }
      .pull-left {
        font-size: 12px;
        left: 0;
        position: absolute;
        top: 48px;
        @media screen and ( max-width: 399px ) {
          top: 72px;
          max-width: 130px;
        }
        a {
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
  .node-readmore,
  .more-link {
    font-size: 14px;
    line-height: 18px;
    a {
      color: #000;
      font-weight: bold;
      &:after {
        color: #ed1c24;
        content: '\f101';
        font-family: FontAwesome;
        font-style: normal;
        font-weight: bold;
        padding-left: 2px;
      }
    }
  }
  a.views-more-link {
    color: #000;
    display: block;
    font-weight: bold;
    text-align: right;
    &:after {
      color: #ed1c24;
      content: '\f101';
      font-family: FontAwesome;
      font-style: normal;
      font-weight: bold;
      padding-left: 2px;
    }
  }
  /* white typo on primary button and tab */
  .tabs--primary.nav-pills > li > a.active,
  a.btn-primary {
    color: #fff !important;
  }
  #comment-form {
    input[type=submit]#edit-submit {
      background-color: #ed1c24 !important;
      border: none;
      border-radius: 0 !important;
      color: #fff !important;
      display: inline-block;
      font-size: 15px;
      height: 30px;
      padding: 0px 8px;
      right: 0px;
      text-align: center;
      top: 0;
      min-width: 30px;
    }
  }
}

