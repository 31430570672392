.field-name-field-dkan-harvest-source-uri {
  word-break: break-word;
}
.field-name-field-data-dictionary .form-control.form-textarea {
  border-radius: 4px 4px 0 0;
  padding: 5px 12px;
}
.field-name-field-data-dictionary .text-format-wrapper .description {
  margin-bottom: 10px;
}


.field-name-field-dataset-tags .field-item{
  display: inline-block;
  padding: 2px 10px 2px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 0 5px 5px 0;
  background-color: #F3F3F3;
  margin: 3px 5px 3px 20px;
  position: relative;
  color: #333333;
  border: 1px solid #ddd;
  border-left: 0;
  z-index: 2;
}
.field-name-field-dataset-tags .field-item a:before {
  content: "";
  width: 2px;
  height: 2px;
  background: #777777;
  position: absolute;
  top: 11px;
  left: -5px;
  border-radius: 0;
}

.field-name-field-dataset-tags .field-item a:after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #F3F3F3;
  transform: rotate(45deg);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: -9px;
  z-index: -1;
  border: 1px solid #ddd;
  border-top: none;
  border-right: none;
}

.field-name-field-dataset-tags .field-item {
  display: inline-block;
}